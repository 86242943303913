import i18n from "../i18n";
import {
  BookOpenIcon,
  HomeIcon,
  MicrophoneIcon,
  QuestionMarkCircleIcon
} from "@heroicons/react/24/outline";

export const navigation = {
  items: [
    {
      label: i18n.t("navigation.dashboard"),
      route: "/dashboard",
      icon: HomeIcon
    },
    {
      label: i18n.t("navigation.transcriptions"),
      route: "/resource/transcriptions",
      icon: MicrophoneIcon
    },
    {
      label: i18n.t("navigation.public_transcriptions"),
      route: "/resource/public_transcriptions",
      icon: BookOpenIcon
    },
    {
      label: i18n.t("navigation.help"),
      route: "https://howto.ilovetranscriptions.com",
      icon: QuestionMarkCircleIcon
    }
  ]
};

import { PropsWithChildren } from "react";
import SideBar from "../common/SideBar";
import NavBar from "../common/NavBar";
import { useIdentity } from "../../hooks/IdentityProvider";
import classNames from "classnames";
import useWindowSize from "../../hooks/useWindowSize";

export default function BaseLayout(props: PropsWithChildren) {
  const { identity } = useIdentity();
  const {isMobile} = useWindowSize();

  return (
    <>
      {identity && <SideBar />}
      {identity && <NavBar />}
      <div
        className={classNames("h-full min-h-full", {
          "ml-16": !!identity && !isMobile,
        })}
      >
        {props.children}
      </div>
    </>
  );
}

export default {
  translation: {
    // Global
    "global.termsOfService": "Términos y condiciones",
    "global.subscribe": "Suscribirse",

    // Loader
    "loader.loading": "Cargando",
    "loader.pleaseWait": "Por favor espere",

    // Navigation
    "navigation.dashboard": "Dashboard",
    "navigation.settings": "Configuración",
    "navigation.transcriptions": "Transcripciones",
    "navigation.public_transcriptions": "Transcripciones públicas",
    "navigation.help": "Ayuda",

    // UserMenu
    "userMenu.settings": "Configuración",
    "userMenu.logout": "Cerrar sesión",

    // Error
    "error.pageTitle": "Error",
    "error.notFound": "Página no encontrada",
    "error.notFoundMessage": "La página que buscas no existe.",
    "error.serverError": "Ocurrió un error al procesar tu solicitud.",
    "error.home": "Ir al inicio",

    // Settings
    "settings.title": "Configuración",
    "settings.noSubscription.title": "Suscribirse a I ♡ Transcriptions",
    "settings.noSubscription.heading": "No cuentas con una suscripción activa",
    "settings.noSubscription.comment": "Suscribite haciendo click en el botón de abajo.",
    "settings.subscription.title": "Suscrito a I ♡ Transcriptions",
    "settings.subscription.heading": "Tu suscripción está activa",
    "settings.subscription.comment": "Si quieres hacer cambios en tu suscripción, puedes hacerlo desde el botón de abajo.",
    "settings.subscription.manage": "Administrar suscripción",
    "settings.workspace.title": "Espacio de trabajo",
    "settings.workspace.name": "Nombre del espacio de trabajo",
    "settings.workspace.id": "Id del espacio de trabajo",
    "settings.workspace.user": "Usuario",
    "settings.workspace.userId": "Id del usuario",
    "settings.workspace.edit": "Actualizar datos",

    // TableView
    "tableView.pager.showing": "Mostrando {{from}} a {{to}} de {{count}}",
    "tableView.empty": "No hay datos para mostrar",
    "tableView.loading": "Cargando datos, por favor espera",
    "tableView.error": "Los datos no se pudieron cargar",
    "tableView.errored":
      "Los datos no se pudieron cargar debido a un error del servidor, por favor, intenta nuevamente más tarde.",

    // FormView
    "formView.create": "Crear",
    "formView.save": "Guardar",
    "formView.delete": "Eliminar",
    "formView.validationFailed": "No se pudieron guardar los datos, por favor revisa los campos en rojo.",
    "formView.storeSucceeded": "Guardado exitosamente",
    "formView.genericError":
      "No se pudieron guardar los datos, por favor intenta nuevamente.",
    "formView.placeholder": "Selecciona {{ label }}",
    "formView.deleteConfirmation": "¿Eliminar?",
    "formView.deleteConfirmationButton": "Si, eliminar",
    "formView.deleteConfirmationMessage": "Esta acción no se puede deshacer.",
    "formView.deleteCancel": "Cancelar",
    "formView.deleteSucceeded": "Eliminado exitosamente",
    "formView.deleteFailed": "No se pudo eliminar",
    "formView.field.select.placeholder": "Selecciona {{ label }}",

    // Messages
    "messages.fatal": "Fatal: no se pudo iniciar la conexión con la API.",
    "messages.invalidServerResponse": "El formato de respuesta del servidor es incorrecto.",

    // Alerts
    "alerts.demo.title": "DEMO",
    "alerts.demo.heading": "Esás usando la versión de prueba de I ♡ Transcriptions.",
    "alerts.demo.comment": "Esta versión tiene funcionalidades limitadas.",
    "alerts.subscription.title": "No tienes una suscripción activa",
    "alerts.subscription.comment": "Necesitas una suscripción activa para acceder a todas las funcionalidades de I ♡ Transcriptions. Haz click en el botón para suscribirte.",

    // Login
    "login.pageTitle": "Iniciar sesión",
    "login.welcome": "Bienvenido",
    "login.pleaseLogin": "Inicia sesión para continuar",
    "login.accessWithGoogle": "Accede con Google",
    "login.notice":
      "Por razones de seguridad, solo permitimos el acceso con cuentas de Google.",
    "login.loggingIn": "Iniciando sesión",

    // Logout
    "logout.pageTitle": "Cerrar sesión",
    "logout.loggingOut": "Cerrando sesión",

    // ToS
    "tos.title": "Términos y condiciones",
    "tos.heading": "Para usar I ♡ Transcriptions debes aceptar los siguientes términos y condiciones:",
    "tos.url": "https://dl.komoni.mx/ilovetranscriptions/tos.html",
    "tos.link": "Click <0>aquí</0> para abrir en una nueva ventana",
    "tos.comment": "Al acer click, confirmas que aceptas los <br/>términos y condiciones de I ♡ Transcriptions que se muestran arriba",
    "tos.accept": "Acepto",
    "tos.decline": "No acepto",
    "tos.verify": "Verificando...",
    "tos.sending": "Enviando datos",

    // Onboarding
    "onboarding.title": "Plan",
    "onboarding.loading": "Cargando planes",
    "onboarding.included": "Incluído en tu plan",
    "onboarding.choose": "Escoge tu plan",
    "onboarding.subscription.anual": "Suscripción anual",
    "onboarding.subscription.monthly": "Suscripción mensual",
    "onboarding.subscription.discount": "50% OFF!",
    "onboarding.subscription.notes": "I ♡ Transcriptions no almacena información de tarjetas de crédito, el pago se procesa a través de Stripe, una pasarela de pago segura. Al hacer clic en «Suscribirse», confirma de nuevo que ha leído y aceptado nuestros términos y condiciones.",
    "onboarding.subscription.success": "Gracias por suscribirte a I ♡ Transcriptions",
    "onboarding.subscription.provision": "Por favor espera mientras se procesa tu suscripción",
    "onboarding.subscriptions.feature1": "Transcripciones <0>Ilimitadas</0>",
    "onboarding.subscriptions.feature2": "Descarga en diferentes formatos",
    "onboarding.subscriptions.feature3": "Cancel en cualquier momento",
    "onboarding.currencyModal.title": "Selecciona tu divisa",
    "onboarding.currencyModal.text": "Seleccione la divisa de su tarjeta de crédito, tenga en cuenta que no podrá cambiarla posteriormente sin ponerse en contacto con el servicio de asistencia.",
    "onboarding.currencyModal.validation": "Por favor seleccione una divisa",
    "onboarding.currencyModal.placeholder": "Selecciona tu divisa",
    "onboarding.currencyModal.submit": "OK",

    // Dashboard
    "dashboard.welcome": "¡Bienvenido, {{ name }}!",
    "dashboard.usedTime": "Tiempo usado",
    "dashboard.transcriptions": "Transcripciones",
    "dashboard.publicTranscriptions": "Transcripciones públicas",
    "dashboard.recentPublicTranscriptions": "Transcripciones públicas recientes",
    "dashboard.recentTranscriptions": "Transcripciones recientes",
    "dashboard.more": "Más",
    "dashboard.recentEmpty": "No hay transcripciones recientes",
    "dashboard.createTranscription": "Crea tu primera transcripción",

    // Workspace
    "resource.workspace.editPageTitle": "Editar {{ title }}",
    "resource.workspace.form.name": "Nombre",
    "resource.workspace.form.currency": "Divisa",
    "resource.workspace.form.currency.help": "Seleccione la moneda de su tarjeta de crédito. Tenga en cuenta que no se puede cambiar una vez establecida. Si necesitas cambiarla, ponte en contacto con el servicio de asistencia.",
    
    // Currencies
    "currency.usd": "USD (United States Dollar)",
    "currency.mxn": "MXN (Peso Mexicano)",

    // Transcriptions
    "resource.transcriptions.indexPageTitle": "Transcripciones",
    "resource.transcriptions.createPageTitle": "Cargar archivos",
    "resource.transcriptions.create": "Nueva transcripción",
    "resource.transcriptions.table.status": "Estatus",
    "resource.transcriptions.table.duration": "Duración",
    "resource.transcriptions.table.name": "Nombre",
    "resource.transcriptions.editPageTitle": "{{ title }}",
    "resource.transcriptions.table.created_at": "Fecha",
    "resource.transcriptions.table.public": "Pública",

    // Media Editor
    "mediaEditor.title.readOnly": "Editor de transcripciones (solo lectura)",
    "mediaEditor.title": "Editor de transcripciones",
    "mediaEditor.actions": "Acciones",
    "mediaEditor.download": "Descargar",
    "mediaEditor.details": "Detalles",
    "mediaEditor.processing": "Procesando archivo",
    "mediaEditor.processingMessage": "Por favor vuelve más tarde",
    "mediaEditor.backToList": "Regresar a la lista",
    "mediaEditor.error": "No se puede abrir esta transcripción debido a un error",
    "mediaEditor.unsavedChangesTitle": "Cambios sin guardar",
    "mediaEditor.unsavedChangesMessage": "Necesitas guardar los cambios antes de de descargar.",
    "mediaEditor.owner": "Cargado por",
    "mediaEditor.renameSpeaker": "Renombrar hablante",
    "mediaEditor.renameCancel": "Cancelar",
    "mediaEditor.renameConfirm": "OK",
    
    // Media Uploader
    "mediaUploader.title": "Nueva transcripción",
    "mediaUploader.field.name": "Nombre",
    "mediaUploader.field.language": "Idioma",
    "mediaUploader.field.engine": "Motor",
    "mediaUploader.field.diarization": "Hablantes",
    "mediaUploader.field.diarizationTitle": "Detectar hablantes",
    "mediaUploader.field.diarizationWarning": "Esta opción puede aumentar el tiempo de procesamiento hasta el triple",
    "mediaUploader.cancel": "Cancelar",
    "mediaUploader.submit": "Cargar",
    "mediaUploader.confirmDeleteTitle": "¿Eliminar transcripción?",
    "mediaUploader.confirmDelete": "Esta acción no se puede deshacer.",
    "mediaUploader.confirmDeleteAccept": "Si, eliminar",
    "mediaUploader.confirmDeleteDeny": "Cancelar",
    "mediaUploader.drop.title": "Arrastra tu archivo aquí o haz click para seleccionar",
    "mediaUploader.drop.button": "Cargar archivo",
    "mediaUploader.selectLanguage": "Selecciona un idioma",
    "mediaUploader.largeFile": "<0>Tu archivo pesa {{size}}, es un poco grande.</0><br/>Aún puedes subirlo, pero, para mejorar la carga, es posible que desee comprimirlo primero.",
    "mediaUploader.success": "{{ name }} cargado exitosamente",
    "mediaUploader.successMessage": "Medios subidos, la transcripción estará disponible pronto, vaya a <0>transcripciones</0> para ver el progreso.",
    "mediaUploader.dropped.title": "Ponga su archivo aquí",
    "mediaUploader.drop.limit": "Hasta 512Mb o 3h por archivo y un máximo de 5 archivos",
    "mediaUploader.drop.fileLimitExceeded": "No se pudo agregar {{name}} por que excede los límites permitidos.",

    // Public Transcriptions
    "resource.public_transcriptions.indexPageTitle": "Transcripciones públicas",
    "resource.public_transcriptions.showPageTitle": "{{ title }}",

    // Transcription Statuses
    "resource.transcriptions.status.waiting": "Esperando",
    "resource.transcriptions.status.waiting.description": "Se ha creado la transcripción pero no se ha subido el archivo.",
    "resource.transcriptions.status.uploading": "Cargando",
    "resource.transcriptions.status.uploading.description": "Se está subiendo el archivo.",
    "resource.transcriptions.status.validating": "Validando",
    "resource.transcriptions.status.validating.description": "Se está validando el archivo subido.",
    "resource.transcriptions.status.pending": "Pendiente",
    "resource.transcriptions.status.pending.description": "El archivo ha sido subido y está esperando ser procesado. Si no tienes minutos suficientes en tu cuenta, se transcribirá cuando los tengas.",
    "resource.transcriptions.status.queued": "En cola",
    "resource.transcriptions.status.queued.description": "El archivo esta en la cola de transcripción esperando ser procesado.",
    "resource.transcriptions.status.processing": "Procesando",
    "resource.transcriptions.status.processing.description": "El archivo esta siendo procesado para transcripción, esto puede tardar dependiendo de la duración del mismo.",
    "resource.transcriptions.status.failed": "Fallida",
    "resource.transcriptions.status.failed.description": "",
    "resource.transcriptions.status.completed": "Completada",
    "resource.transcriptions.status.completed.description": "",
    "resource.transcriptions.status.expired": "Expirada",
    "resource.transcriptions.status.expired.description": "Los datos fueron eliminados automáticamente después de 24 horas y no se pueden procesar.",
    "resource.transcriptions.status.preparing": "Preparando",
    "resource.transcriptions.status.preparing.description": "Preparando el archivo para enviarlo al motor de transcrición.",
    
    // Engines
    "engine.whispy-tiny": "🐧 Pinguino",
    "engine.whispy-base": "🐘 Elefante",
    "engine.whispy-small": "🐊 Cocodrilo",
    "engine.whispy-medium": "🦒 Jirafa",
    "engine.whispy-large": "🐬 Delfín",

    "engine.whispy-tiny.description": "Súper rápido, pero no muy preciso",
    "engine.whispy-base.description": "Velocidad y precisión medias",
    "engine.whispy-small.description": "Buen equilibrio entre velocidad y precisión",
    "engine.whispy-medium.description": "Preciso pero lento",
    "engine.whispy-large.description": "Súper preciso, pero muy lento",

    // Languages
    "language.en": "Inglés",
    "language.es": "Español",
    "language.ja": "Japonés",
  }
};

import { app } from "../../configuration/configuration";
import UserMenu from "./UserMenu";
import React from "react";
import LanguageSwitcher from "./LanguageSwitcher";

export default function NavBar() {
  return (
    <nav className="fixed top-0 z-20 h-16 w-screen animate-fade-down border-b border-gray-100 bg-white pl-16 animate-duration-500">
      <div className="flex h-16 items-center pr-6 sm:px-6">
        <div className="text-lg font-medium uppercase text-slate-500 items-center hidden sm:flex">
          {app.name} <span className="text-sm ml-2 px-1.5 py-0.5 rounded font-semibold text-white bg-blue-600 ">BETA</span>
        </div>
        <div className="flex-1"></div>
        <div className="flex items-center">
          <UserMenu />
          <LanguageSwitcher />
        </div>
      </div>
    </nav>
  );
}

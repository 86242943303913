export default {
  translation: {
    // Global
    "global.comingSoon": "近日公開",
    "global.subscribe": "サブスクリプション購入",

    // Loader
    "loader.loading": "読み込み中",
    "loader.pleaseWait": "少々お待ちください",

    // Navigation
    "navigation.dashboard": "ダッシュボード",
    "navigation.settings": "設定",
    "navigation.transcriptions": "書き起こし",
    "navigation.public_transcriptions": "公式書き起こし",
    "navigation.help": "サポート",

    // UserMenu
    "userMenu.settings": "設定",
    "userMenu.logout": "ログアウト",

    // Error
    "error.pageTitle": "エラー",
    "error.notFound": "エラー",
    "error.notFoundMessage": "お探しのページは存在しません。",
    "error.serverError": "リクエストの処理中にエラーが発生しました。",
    "error.home": "ホームへ",

    // Settings
    "settings.title": "設定",
    "settings.noSubscription.title": "I ♡ Transcriptionsのサブスクリプションを購入",
    "settings.noSubscription.heading": "サブスクリプションがありません",
    "settings.noSubscription.comment": "下記のボタンでサブスクリプションの購入ができます",
    "settings.subscription.title": "I ♡ Transcriptions有料会員",
    "settings.subscription.heading": "サブスクリプションがあります",
    "settings.subscription.comment": "サブスクリプションを変更したい場合は、[サブスクリプションの管理]ボタンをクリックししてください。",
    "settings.subscription.manage": "サブスクリプションの管理",
    "settings.workspace.title": "ワークスペース",
    "settings.workspace.name": "ワークスペース名",
    "settings.workspace.id": "ワークスペースID",
    "settings.workspace.user": "ワークスペースユーザー",
    "settings.workspace.userId": "ワークスペースユーザーID",
    "settings.workspace.edit": "ワークスペース編集",

    // TableView
    "tableView.pager.showing": "{{from}} 〜 {{to}} / {{count}}",
    "tableView.empty": "データがありません",
    "tableView.loading": "読み込み中、少々お待ちください",
    "tableView.error": "データの読み込みに失敗しました",
    "tableView.errored":
      "サーバーエラーによりデータを読み込めませんでした。",

    // FormView
    "formView.create": "新規作成",
    "formView.save": "保存",
    "formView.delete": "削除",
    "formView.validationFailed": "保存に失敗しました、赤い項目を確認してください",
    "formView.storeSucceeded": "保存されました",
    "formView.genericError":
      "保存中にエラーが発生しました。",
    "formView.placeholder": "{{ label }}を選択",
    "formView.deleteConfirmation": "削除しますか?",
    "formView.deleteConfirmationButton": "はい, 削除する",
    "formView.deleteConfirmationMessage": "この操作は取り消すことができない",
    "formView.deleteCancel": "キャンセル",
    "formView.deleteSucceeded": "削除されました",
    "formView.deleteFailed": "削除できませんでした",
    "formView.field.select.placeholder": "{{ label }}を選択",

    // Messages
    "messages.fatal": "API接続の開始に失敗しました。",
    "messages.invalidServerResponse": "サーバーの応答形式が正しくありません。",
    
    // Alerts
    "alerts.demo.title": "トライアル",
    "alerts.demo.heading": "I ♡ Transcriptionsのトライアル版を使用しています。",
    "alerts.demo.comment": "機能が制限される場合がありますので、プランへの加入をお勧めします。",
    "alerts.subscription.title": "サブスクリプションがありません",
    "alerts.subscription.comment": "サービスを利用するには、有効なサブスクリプションが必要です。サブスクリプションがないと、機能が制限されます。サブスクリプションを購入するには、サブスクリプションボタンをクリックしてください。",

    // Login
    "login.pageTitle": "ログイン",
    "login.welcome": "ようこそ",
    "login.pleaseLogin": "ログインしてください",
    "login.accessWithGoogle": "Googleログイン",
    "login.notice":
      "セキュリティ上の理由から、Googleアカウントでのみアクセスを許可しています。",
    "login.loggingIn": "処理中",

    // Logout
    "logout.pageTitle": "ログアウト",
    "logout.loggingOut": "処理中",

    // ToS
    "tos.title": "利用規約",
    "tos.heading": "I ♡ Transcriptionsを利用するには、以下の利用規約に同意する必要があります:",
    "tos.url": "https://dl.komoni.mx/ilovetranscriptions/tos_en.html",
    "tos.link": "<0>別タブ</0> で開く.",
    "tos.comment": "クリックすることで、上記の利用規約に同意したものとみなされます。",
    "tos.accept": "同意する",
    "tos.decline": "同意しない",
    "tos.verify": "処理中...",
    "tos.sending": "送信中",

    // Onboarding
    "onboarding.title": "プラン",
    "onboarding.loading": "プランを読み込み中",
    "onboarding.included": "プランに含まれるもの:",
    "onboarding.choose": "プランを選択",
    "onboarding.subscription.anual": "年額サブスクリプション",
    "onboarding.subscription.monthly": "月額サブスクリプション",
    "onboarding.subscription.discount": "50% OFF!",
    "onboarding.subscription.notes": "I ♡ Transcriptionsはクレジットカード情報を保存せず、支払いは安全な支払ゲートウェイであるStripeを通じて処理されます。Subscribeをクリックすることで、当社の利用規約を読み、同意したことを再度確認します。",
    "onboarding.subscription.success": "ご購読ありがとうございます！",
    "onboarding.subscription.provision": "購読が有効になるまでしばらくお待ちください。",
    "onboarding.subscriptions.feature1": "<0>無制限</0>書き起こし",
    "onboarding.subscriptions.feature2": "様々なフォーマットでダウンロード",
    "onboarding.subscriptions.feature3": "いつでもキャンセル",
    "onboarding.currencyModal.title": "通貨を選択",
    "onboarding.currencyModal.text": "クレジットカードの通貨を選択してください。後でサポートに連絡せずに変更することはできませんのでご注意ください。",
    "onboarding.currencyModal.validation": "通貨を選択してください",
    "onboarding.currencyModal.placeholder": "通貨を選択",
    "onboarding.currencyModal.submit": "OK",

    // Dashboard
    "dashboard.welcome": "{{ name }}さん、ようこそ!",
    "dashboard.usedTime": "使用時間",
    "dashboard.transcriptions": "書き起こし",
    "dashboard.publicTranscriptions": "公式書き起こし",
    "dashboard.recentPublicTranscriptions": "新着公式書き起こし",
    "dashboard.recentTranscriptions": "新着書き起こし",
    "dashboard.more": "一覧へ",
    "dashboard.recentEmpty": "書き起こしがありません",
    "dashboard.createTranscription": "書き起こしを作成",

    // Workspace
    "resource.workspace.editPageTitle": "{{ title }}編集",
    "resource.workspace.form.name": "名前",
    "resource.workspace.form.currency": "通貨",
    "resource.workspace.form.currency.help": "クレジットカードの通貨を選択してください。一度設定すると変更できませんのでご注意ください。変更が必要な場合は、サポートまでご連絡ください。",

    // Currencies
    "currency.usd": "USD (米ドル)",
    "currency.mxn": "MXN (メキシコペソ)",

    // Transcriptions
    "resource.transcriptions.indexPageTitle": "書き起こし",
    "resource.transcriptions.createPageTitle": "新規作成",
    "resource.transcriptions.create": "新規作成",
    "resource.transcriptions.table.status": "状態",
    "resource.transcriptions.table.duration": "持続時間",
    "resource.transcriptions.table.name": "名前",
    "resource.transcriptions.editPageTitle": "{{ title }}",
    "resource.transcriptions.table.created_at": "作成日時",
    "resource.transcriptions.table.public": "公式",

    // Media Editor
    "mediaEditor.title.readOnly": "書き起こし編集 (読み取り専用)",
    "mediaEditor.title": "書き起こし編集",
    "mediaEditor.actions": "アクション",
    "mediaEditor.download": "ダウンロード",
    "mediaEditor.details": "詳細",
    "mediaEditor.processing": "ファイルを処理中",
    "mediaEditor.processingMessage": "しばらくしてからもう一度お試しください",
    "mediaEditor.backToList": "一覧へ戻る",
    "mediaEditor.error": "エラーによりこの書き起こしを開くことができません。",
    "mediaEditor.unsavedChangesTitle": "保存されていない変更",
    "mediaEditor.unsavedChangesMessage": "ダウンロードする前に変更を保存する必要があります。",
    "mediaEditor.owner": "アップロードアカウント",
    "mediaEditor.renameSpeaker": "話者名変更",
    "mediaEditor.renameCancel": "キャンセル",
    "mediaEditor.renameConfirm": "OK",
    
    // Media Uploader
    "mediaUploader.title": "メディアアップローダー",
    "mediaUploader.field.name": "名前",
    "mediaUploader.field.language": "言語",
    "mediaUploader.field.engine": "エンジン",
    "mediaUploader.field.diarization": "話者",
    "mediaUploader.field.diarizationTitle": "話者を識別",
    "mediaUploader.field.diarizationWarning": "話者識別は処理時間を最大3倍に増加させることがあります",
    "mediaUploader.cancel": "キャンセル",
    "mediaUploader.submit": "アップロード",
    "mediaUploader.confirmDeleteTitle": "書き起こしを削除?",
    "mediaUploader.confirmDelete": "この操作は取り消すことができない",
    "mediaUploader.confirmDeleteAccept": "はい, 削除",
    "mediaUploader.confirmDeleteDeny": "キャンセル",
    "mediaUploader.drop.title": "ここにファイルをドロップするか、下のボタンをクリックしてください。",
    "mediaUploader.drop.button": "ファイルを選択",
    "mediaUploader.selectLanguage": "言語を選択",
    "mediaUploader.largeFile": "<0>ファイルの重さは{{size}}で、大きい方です。</0><br/>アップロードできますが、アップロードを向上させるために、最初に圧縮した方がよいでしょう。",
    "mediaUploader.success": "{{ name }}がアップロードされました",
    "mediaUploader.successMessage": "メディアはアップロードされ、<0>書き起こし一覧</0>で処理状況が見れます。",
    "mediaUploader.dropped.title": "ここにファイルをドロップする",
    "mediaUploader.drop.limit": "最大512Mb/3h。ファイル五つまでです",
    "mediaUploader.drop.fileLimitExceeded": "制限を超えるため、「{{name}} 」を追加できませんでした",

    // Public Transcriptions
    "resource.public_transcriptions.indexPageTitle": "公式書き起こし",
    "resource.public_transcriptions.showPageTitle": "{{ title }}",
    
    // Transcription Statuses
    "resource.transcriptions.status.waiting": "作成済",
    "resource.transcriptions.status.waiting.description": "書き起こしは作成されましたが、ファイルがアップロードされていません。",
    "resource.transcriptions.status.uploading": "アップロード中",
    "resource.transcriptions.status.uploading.description": "ファイルがアップロード中です。",
    "resource.transcriptions.status.validating": "検証中",
    "resource.transcriptions.status.validating.description": "アップロードされたファイルは検証中です。",
    "resource.transcriptions.status.pending": "保留",
    "resource.transcriptions.status.pending.description": "ファイルはアップロードされ、処理待ちです。",
    "resource.transcriptions.status.queued": "処理待ち",
    "resource.transcriptions.status.queued.description": "ファイルは書き起こしキューで処理待ちの状態です。",
    "resource.transcriptions.status.processing": "処理中",
    "resource.transcriptions.status.processing.description": "書き起こしが処理されています。",
    "resource.transcriptions.status.completed": "処理完了",
    "resource.transcriptions.status.completed.description": "",
    "resource.transcriptions.status.failed": "処理失敗",
    "resource.transcriptions.status.failed.description": "",
    "resource.transcriptions.status.expired": "期限切れ",
    "resource.transcriptions.status.expired.description": "データは24時間後に自動的に削除され、処理することはできない。",
    "resource.transcriptions.status.preparing": "準備中",
    "resource.transcriptions.status.preparing.description": "書き起こし用のデータを作成。",

    // Engines
    "engine.whispy-tiny": "🐧 ペンギン",
    "engine.whispy-base": "🐘 ゾウ",
    "engine.whispy-small": "🐊 ワニ",
    "engine.whispy-medium": "🦒 キリン",
    "engine.whispy-large": "🐬 イルカ",

    "engine.whispy-tiny.description": "超高速だが、あまり正確ではない",
    "engine.whispy-base.description": "中程度のスピードと精度",
    "engine.whispy-small.description": "スピードと精度のバランスが良い",
    "engine.whispy-medium.description": "正確だが遅い",
    "engine.whispy-large.description": "超精密だが、本当に遅い",

    // Languages
    "language.en": "英語",
    "language.es": "スペイン語",
    "language.ja": "日本語",
  }
};
import { ArrowLeftIcon, Bars3Icon } from "@heroicons/react/24/outline";
import { Cog6ToothIcon, FireIcon, HeartIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { navigation } from "../../configuration/navigation";
import useWindowSize from "../../hooks/useWindowSize";
import { useEffect, useState } from "react";
import { app } from "../../configuration/configuration";

export default function SideBar() {
  const location = useLocation();
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    setMobileOpen(false);
  }, [location]);

  useEffect(() => {
    if (!isMobile) {
      setMobileOpen(false);
    }
  }, [isMobile]);

  if (isMobile && !mobileOpen) {
    return (
      <div className="border-1 fixed left-0 top-0 z-[100] flex h-16 w-16 animate-fade-down items-center justify-center border-r border-gray-100 animate-duration-500 sm:hidden">
        <button onClick={() => setMobileOpen(true)}>
          <Bars3Icon className="h-10 w-10 text-gray-300" />
        </button>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        "fixed inset-0 z-[100] flex animate-fade bg-[rgba(0,0,0,0.7)] animate-duration-500 sm:w-16 sm:animate-none sm:bg-transparent",
        {
          hidden: false || !mobileOpen && isMobile,
        },
      )}
    >
      <div className="flex w-[70%] animate-fade-right flex-col bg-indigo-500 animate-duration-500 sm:w-16">
        <Tooltip
          id="sidenav-tooltip"
          place="right"
          variant="info"
          className="hidden sm:block"
          style={{ zIndex: 100 }}
        />

        <div className="flex h-16 items-center justify-center bg-indigo-700 sm:w-16">
          <HeartIcon className="hidden h-8 w-8 text-white transition-all duration-500 ease-in-out animate-duration-500 hover:rotate-[360deg] hover:animate-wiggle-more hover:animate-infinite sm:block" />
          <div className="flex w-full items-center p-4 sm:hidden">
            <div className="flex-1 flex justify-start items-center text-sm uppercase text-white">
              {app.name} <span className="ml-2 px-1.5 py-0.5 rounded font-semibold text-white bg-blue-600">β</span>
            </div>
            <button
              className="flex h-8 w-8 items-center justify-center"
              onClick={() => setMobileOpen(false)}
            >
              <ArrowLeftIcon className="h-6 w-6 text-white" />
            </button>
          </div>
        </div>
        <div className="flex-1 pt-2">
          {navigation.items.map((item, index) => {
            const Icon = item.icon;
            const active = location.pathname.startsWith(item.route);
            const props = {
              "data-tooltip-id": "sidenav-tooltip",
              "data-tooltip-content": item.label,
              className: classNames(
                "m-2 flex h-12 sm:w-12 items-center px-3 rounded-lg transition bg-indigo-400 bg-opacity-30 hover:bg-indigo-600 sm:bg-indigo-500 sm:bg-opacity-100 sm:px-0 sm:justify-center",
                {
                  "bg-indigo-400 !bg-opacity-100": active,
                },
              ),
            };

            if (item.route.startsWith("http")) {
              return (
                <a
                  key={index}
                  {...props}
                  href={item.route}
                  target="_blank"
                  onClick={() => setMobileOpen(false)}
                >
                  <Icon className="h-6 w-6 text-white" />
                  <span className="ml-2 line-clamp-1 overflow-hidden text-ellipsis break-all text-white sm:hidden">
                    {item.label}
                  </span>
                </a>
              );
            } else {
              return (
                <Link key={index} {...props} to={item.route}>
                  <Icon className="h-6 w-6 text-white" />
                  <span className="ml-2 line-clamp-1 overflow-hidden text-ellipsis break-all text-white sm:hidden">
                    {item.label}
                  </span>
                </Link>
              );
            }
          })}
        </div>
        <div>
          <Link
            to="/settings"
            data-tooltip-id="sidenav-tooltip"
            data-tooltip-content={t("navigation.settings")}
            className={classNames(
              "m-2 flex h-12 w-12 items-center justify-center rounded-lg transition hover:bg-indigo-600",
              {
                "bg-indigo-400": location.pathname.startsWith("/settings"),
              },
            )}
          >
            <Cog6ToothIcon className="h-6 w-6 text-white" />
          </Link>
        </div>
      </div>
    </div>
  );
}

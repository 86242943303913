import Loader, { LoaderProps } from "./Loader";
import classNames from "classnames";
import { useEffect, useState } from "react";

type PageLoaderProps = LoaderProps & {
  active: boolean;
};

export default function PageLoader(props: PageLoaderProps) {
  const [isVisible, setIsVisible] = useState(true);
  const { active } = props;

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (active) {
      setIsVisible(true);
    } else {
      timeout = setTimeout(() => setIsVisible(false), 200);
    }

    return () => clearTimeout(timeout);
  }, [active]);

  if (!isVisible) {
    return null;
  }

  return (
    <div
      className={classNames(
        "fixed inset-0 flex items-center justify-center bg-gray-50 sm:pl-16 pt-16",
        {
          "animate-fade animate-reverse animate-duration-200": !active,
        },
      )}
    >
      <div className="animate-fadeInScale">
        <Loader {...props} />
      </div>
    </div>
  );
}

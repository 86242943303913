export default {
  translation: {
    // Global
    "global.comingSoon": "Coming Soon",
    "global.subscribe": "Subscribe",

    // Loader
    "loader.loading": "Loading",
    "loader.pleaseWait": "Please wait",

    // Navigation
    "navigation.dashboard": "Dashboard",
    "navigation.settings": "Settings",
    "navigation.transcriptions": "Transcriptions",
    "navigation.public_transcriptions": "Public Transcriptions",
    "navigation.help": "Help",

    // UserMenu
    "userMenu.settings": "Settings",
    "userMenu.logout": "Logout",

    // Error
    "error.pageTitle": "Error",
    "error.notFound": "Not found",
    "error.notFoundMessage": "The page you are looking for does not exist.",
    "error.serverError": "An error occurred while processing your request.",
    "error.home": "Go to Home",

    // Settings
    "settings.title": "Settings",
    "settings.noSubscription.title": "Subscribe to I ♡ Transcriptions",
    "settings.noSubscription.heading": "You do not have an active subscription",
    "settings.noSubscription.comment": "Purchase a subscription using the button below",
    "settings.subscription.title": "Subscribed to I ♡ Transcriptions",
    "settings.subscription.heading": "Your subscription is active",
    "settings.subscription.comment": "If you want to make changes to your subscription, click the Manage subscription button.",
    "settings.subscription.manage": "Manage subscription",
    "settings.workspace.title": "Workspace",
    "settings.workspace.name": "Workspace name",
    "settings.workspace.id": "Workspace Id",
    "settings.workspace.user": "Workspace user",
    "settings.workspace.userId": "User Id",
    "settings.workspace.edit": "Update workspace",

    // TableView
    "tableView.pager.showing": "Showing {{from}} to {{to}} of {{count}}",
    "tableView.empty": "No data to display",
    "tableView.loading": "Loading data, please wait",
    "tableView.error": "Data could not be loaded",
    "tableView.errored":
      "The data could not be loaded due to a server error, please try again later.",

    // FormView
    "formView.create": "Create",
    "formView.save": "Save",
    "formView.delete": "Delete",
    "formView.validationFailed": "Could not save, please check the red fields.",
    "formView.storeSucceeded": "Saved successfully",
    "formView.genericError":
      "An error occurred while saving, please try again later.",
    "formView.placeholder": "Select {{ label }}",
    "formView.deleteConfirmation": "Delete?",
    "formView.deleteConfirmationButton": "Yes, delete",
    "formView.deleteConfirmationMessage": "This action cannot be undone",
    "formView.deleteCancel": "Cancel",
    "formView.deleteSucceeded": "Deleted successfully",
    "formView.deleteFailed": "Could not delete",
    "formView.field.select.placeholder": "Select {{ label }}",

    // Messages
    "messages.fatal": "Fatal: Failed to initiate api connection.",
    "messages.invalidServerResponse": "Server response format is incorrect.",

    // Alerts
    "alerts.demo.title": "DEMO",
    "alerts.demo.heading": "You are using the trial version of I ♡ Transcriptions.",
    "alerts.demo.comment": "Functionality may be limited, we recommend subscribing to a plan.",
    "alerts.subscription.title": "You do not have an active subscription",
    "alerts.subscription.comment": "You need an active subscription to use the service; without it, functionality will be limited. Click the subscribe button to purchase a subscription.",

    // Login
    "login.pageTitle": "Login",
    "login.welcome": "Welcome",
    "login.pleaseLogin": "Please login to continue",
    "login.accessWithGoogle": "Access with Google",
    "login.notice":
      "For security reasons, we only allow access with Google accounts.",
    "login.loggingIn": "Logging in",

    // Logout
    "logout.pageTitle": "Logout",
    "logout.loggingOut": "Logging out",

    // ToS
    "tos.title": "Terms and Conditions",
    "tos.heading": "To use I ♡ Transcriptions you must accept the following terms and conditions:",
    "tos.url": "https://dl.komoni.mx/ilovetranscriptions/tos_en.html",
    "tos.link": "Click <0>here</0> to view in a new window.",
    "tos.comment": "By clicking, you confirm that you accept the <br/>terms and conditions shown above",
    "tos.accept": "I accept",
    "tos.decline": "I do not accept",
    "tos.verify": "Verifying your request...",
    "tos.sending": "Sending data",

    // Onboarding
    "onboarding.title": "Plan",
    "onboarding.loading": "Loading plans",
    "onboarding.included": "Included in your plan:",
    "onboarding.choose": "Choose your plan",
    "onboarding.subscription.anual": "Annual subscription",
    "onboarding.subscription.monthly": "Monthly subscription",
    "onboarding.subscription.discount": "50% OFF!",
    "onboarding.subscription.notes": "I ♡ Transcriptions does not store credit card information, payment is processed through Stripe, a secure payment gateway. By clicking \"Subscribe\", you confirm again that you have read and accepted our terms and conditions.",
    "onboarding.subscription.success": "Thank you for subscribing!",
    "onboarding.subscription.provision": "Please wait while your subscription is activated.",
    "onboarding.subscriptions.feature1": "<0>Unlimited</0> transcriptions",
    "onboarding.subscriptions.feature2": "Download in different formats",
    "onboarding.subscriptions.feature3": "Cancel anytime",
    "onboarding.currencyModal.title": "Select currency",
    "onboarding.currencyModal.text": "Select the currency of your credit card, please note that it cannot be changed later without contacting support.",
    "onboarding.currencyModal.validation": "Please select a currency",
    "onboarding.currencyModal.placeholder": "Select currency",
    "onboarding.currencyModal.submit": "OK",

    // Dashboard
    "dashboard.welcome": "Welcome, {{ name }}!",
    "dashboard.usedTime": "Used time",
    "dashboard.transcriptions": "Transcriptions",
    "dashboard.publicTranscriptions": "Public transcriptions",
    "dashboard.recentPublicTranscriptions": "Recent public transcriptions",
    "dashboard.recentTranscriptions": "Recent transcriptions",
    "dashboard.more": "More",
    "dashboard.recentEmpty": "You have no transcriptions",
    "dashboard.createTranscription": "Create your first transcription",

    // Workspace
    "resource.workspace.editPageTitle": "Edit {{ title }}",
    "resource.workspace.form.name": "Name",
    "resource.workspace.form.currency": "Currency",
    "resource.workspace.form.currency.help": "Select the currency of your credit card. Please not that it cannot be changed once set. If you need to change it, please contact support.",

    // Currencies
    "currency.usd": "USD (United States Dollar)",
    "currency.mxn": "MXN (Mexican Peso)",

    // Transcriptions
    "resource.transcriptions.indexPageTitle": "Transcriptions",
    "resource.transcriptions.createPageTitle": "Upload files",
    "resource.transcriptions.create": "New transcription",
    "resource.transcriptions.table.status": "Status",
    "resource.transcriptions.table.duration": "Duration",
    "resource.transcriptions.table.name": "Name",
    "resource.transcriptions.editPageTitle": "{{ title }}",
    "resource.transcriptions.table.created_at": "Created at",
    "resource.transcriptions.table.public": "Public",

    // Media Editor
    "mediaEditor.title.readOnly": "Transcription editor (read only)",
    "mediaEditor.title": "Transcription editor",
    "mediaEditor.actions": "Actions",
    "mediaEditor.download": "Download",
    "mediaEditor.details": "Details",
    "mediaEditor.processing": "Processing file",
    "mediaEditor.processingMessage": "Please come back later",
    "mediaEditor.backToList": "Back to list",
    "mediaEditor.error": "Cannot open this transcription due to an error",
    "mediaEditor.unsavedChangesTitle": "Usaved changes",
    "mediaEditor.unsavedChangesMessage": "You need to save your changes before downloading.",
    "mediaEditor.owner": "Uploaded by",
    "mediaEditor.renameSpeaker": "Rename speaker",
    "mediaEditor.renameCancel": "Cancel",
    "mediaEditor.renameConfirm": "OK",
    
    // Media Uploader
    "mediaUploader.title": "New transcription",
    "mediaUploader.field.name": "Name",
    "mediaUploader.field.language": "Language",
    "mediaUploader.field.engine": "Engine",
    "mediaUploader.field.diarization": "Speakers",
    "mediaUploader.field.diarizationTitle": "Detect speakers",
    "mediaUploader.field.diarizationWarning": "This will increase processing time by up to 3 times",
    "mediaUploader.cancel": "Cancel",
    "mediaUploader.submit": "Upload",
    "mediaUploader.confirmDeleteTitle": "Delete transcription?",
    "mediaUploader.confirmDelete": "This action cannot be undone",
    "mediaUploader.confirmDeleteAccept": "Yes, delete",
    "mediaUploader.confirmDeleteDeny": "Cancel",
    "mediaUploader.drop.title": "Drop your files here or click the button below",
    "mediaUploader.drop.button": "Upload file",
    "mediaUploader.selectLanguage": "Select language",
    "mediaUploader.largeFile": "<0>Your file weighs {{size}}, it's on the larger side.</0><br/>You can still upload it, but, to enhance uploading, you may want to compress it first.",
    "mediaUploader.success": "{{ name }} uploaded successfully",
    "mediaUploader.successMessage": "Media uploaded, transcription will be available soon, go to <0>transcriptions</0> to see the progress.",
    "mediaUploader.dropped.title": "Drop your file here",
    "mediaUploader.drop.limit": "Up to 512Mb / 3h files, maximum 5 files",
    "mediaUploader.drop.fileLimitExceeded": "Cannot add {{name}} because it excceds the accepted limits.",

    // Public Transcriptions
    "resource.public_transcriptions.indexPageTitle": "Public Transcriptions",
    "resource.public_transcriptions.showPageTitle": "{{ title }}",

    // Transcription Statuses
    "resource.transcriptions.status.waiting": "Waiting",
    "resource.transcriptions.status.waiting.description": "The transcription has been created but the file has not been uploaded.",
    "resource.transcriptions.status.uploading": "Uploading",
    "resource.transcriptions.status.uploading.description": "The file is being uploaded.",
    "resource.transcriptions.status.validating": "Validating",
    "resource.transcriptions.status.validating.description": "The uploaded file is being validated.",
    "resource.transcriptions.status.pending": "Pending",
    "resource.transcriptions.status.pending.description": "The file has been uploaded and is waiting to be processed. If you do not have enough minutes in your account, it will be transcribed when you do.",
    "resource.transcriptions.status.queued": "Queued",
    "resource.transcriptions.status.queued.description": "The file is in the transcription queue waiting to be processed.",
    "resource.transcriptions.status.processing": "Processing",
    "resource.transcriptions.status.processing.description": "The file is being processed for transcription, this may take time depending on its duration.",
    "resource.transcriptions.status.completed": "Completed",
    "resource.transcriptions.status.completed.description": "",
    "resource.transcriptions.status.failed": "Failed",
    "resource.transcriptions.status.failed.description": "",
    "resource.transcriptions.status.expired": "Expired",
    "resource.transcriptions.status.expired.description": "The data was automatically deleted after 24 hours and cannot be processed.",
    "resource.transcriptions.status.preparing": "Preparing",
    "resource.transcriptions.status.preparing.description": "Preparing the data to send to the transcription engine.",

    // Engines
    "engine.whispy-tiny": "🐧 Penguin",
    "engine.whispy-base": "🐘 Elephant",
    "engine.whispy-small": "🐊 Crocodile",
    "engine.whispy-medium": "🦒 Girafee",
    "engine.whispy-large": "🐬 Dolphin",

    "engine.whispy-tiny.description": "Super fast, but not very accurate",
    "engine.whispy-base.description": "Medium speed and accuracy",
    "engine.whispy-small.description": "Good balance between speed and accuracy",
    "engine.whispy-medium.description": "Precise but slow",
    "engine.whispy-large.description": "Super precise, but really slow",

    // Languages
    "language.en": "English",
    "language.es": "Spanish",
    "language.ja": "Japanese",
  }
};
